var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Argon","title":"Argon"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-tv-2 text-primary',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Visitors',
          icon: 'ni ni-tv-2 text-primary',
          path: '/visitors',
        }}}),(false)?_c('sidebar-item',{attrs:{"opened":"","link":{
          name: 'Examples (API)',
          icon: 'ct-image-icon',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'User Profile', path: '/examples/user-profile' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'User Management',
            path: '/examples/user-management/list-users',
          }}})],1):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{
          name: 'Icons',
          icon: 'ni ni-planet text-blue',
          path: '/icons',
        }}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{
          name: 'Maps',
          icon: 'ni ni-pin-3 text-orange',
          path: '/maps',
        }}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{
          name: 'User Profile',
          icon: 'ni ni-single-02 text-yellow',
          path: '/profile',
        }}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{
          name: 'Tables',
          icon: 'ni ni-bullet-list-67 text-red',
          path: '/tables',
        }}}):_vm._e(),(false)?_c('a',{staticClass:"btn btn-sm btn-danger btn-icon ml-sm-3 d-none d-md-block mb-2 mt-1",attrs:{"href":"https://www.creative-tim.com/product/vue-argon-dashboard-pro-laravel","target":"_blank"}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-download mr-2"})]),_c('span',{staticClass:"nav-link-inner--text"},[_vm._v("Upgrade to PRO")])]):_vm._e(),(false)?_c('a',{staticClass:"btn btn-sm btn-neutral btn-icon ml-sm-3 d-none d-md-block mb-2",attrs:{"href":"https://vue-argon-dashboard-laravel.creative-tim.com/documentation/","target":"_blank"}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-file-alt mr-2"})]),_c('span',{staticClass:"nav-link-inner--text"},[_vm._v("Documentation")])]):_vm._e(),(false)?_c('a',{staticClass:"btn btn-sm btn-info btn-icon ml-sm-3 d-none d-md-block mb-2",attrs:{"href":"https://www.creative-tim.com/product/vue-argon-dashboard-laravel","target":"_blank"}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-gift mr-2"})]),_c('span',{staticClass:"nav-link-inner--text"},[_vm._v("Download now")])]):_vm._e(),(false)?_c('a',{staticClass:"btn btn-sm btn-dark btn-icon ml-sm-3 d-none d-md-block mr-2",attrs:{"href":"https://github.com/creativetimofficial/vue-argon-dashboard-laravel","target":"_blank"}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fab fa-github mr-2"})]),_c('span',{staticClass:"nav-link-inner--text"},[_vm._v("Star us on Github")])]):_vm._e()],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }